import React, { useEffect, useState } from "react";
import "../App.scss";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const BlankLayout = ({}) => {
  return (
    <>
      <Outlet />
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default BlankLayout;
