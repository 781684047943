import { createSlice } from "@reduxjs/toolkit";
import { initial } from "lodash";

  const sport = new URL(window.location.href).pathname.split("/")[2];

  const initialState = {
    restrictions: {
      spr: { min: 0.0, max: 100.0, enabled: false },
      ppr: {
        min: 0.0,
        max: sport === "horseshoes" ? 6.0 : 12.0,
        enabled: false,
      },
      dpr: {
        min: sport === "horseshoes" ? -6.0 : -12.0,
        max: sport === "horseshoes" ? 6.0 : 12.0,
        enabled: false,
      },
      seasonMember: { min: 1, max: 9, enabled: false },
      seasonTier: { min: 1, max: 9, enabled: false },
      seasonPpr: {
        min: 0.0,
        max: sport === "horseshoes" ? 6.0 : 12.0,
        enabled: false,
      },
      seasonDpr: {
        min: sport === "horseshoes" ? -6.0 : -12.0,
        max: sport === "horseshoes" ? 6.0 : 12.0,
        enabled: false,
      },
      duprExists: { min: 2.0, max: 8.0, enabled: false },
      duprSingles: { min: 2.0, max: 8.0, enabled: false },
      duprDoubles: { min: 2.0, max: 8.0, enabled: false },
    },
  };

  export const organizerCreateTournamentSlice = createSlice({
    name: "tournamentForm",
    initialState,
    reducers: {
      setForm(state, action) {
        console.log(
          "set player organizerCreateTournamentSlice action: ",
          action
        );
        const {
          spr,
          ppr,
          dpr,
          seasonMember,
          seasonDpr,
          seasonPpr,
          seasonTier,
          duprSingles,
          duprDoubles,
          duprExists,
        } = action.payload;
        state.restrictions.spr = spr ? spr : state.restrictions.spr;
        state.restrictions.ppr = ppr ? ppr : state.restrictions.ppr;
        state.restrictions.dpr = dpr ? dpr : state.restrictions.dpr;
        state.restrictions.seasonMember = seasonMember
          ? seasonMember
          : state.restrictions.seasonMember;
        state.restrictions.seasonTier = seasonTier
          ? seasonTier
          : state.restrictions.seasonTier;
        state.restrictions.seasonPpr = seasonPpr
          ? seasonPpr
          : state.restrictions.seasonPpr;
        state.restrictions.seasonDpr = seasonDpr
          ? seasonDpr
          : state.restrictions.seasonDpr;
        state.restrictions.duprExists = duprExists
          ? duprExists
          : state.restrictions.duprExists;
        state.restrictions.duprSingles = duprSingles
          ? duprSingles
          : state.restrictions.duprSingles;
        state.restrictions.duprDoubles = duprDoubles
          ? duprDoubles
          : state.restrictions.duprDoubles;
      },
      clearTournamentForm: () => initialState,
    },
  });

export const { setForm, clearTournamentForm } =
  organizerCreateTournamentSlice.actions;

export default organizerCreateTournamentSlice.reducer;
