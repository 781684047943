import React, { useEffect, useState } from "react";
import "../App.scss";
import { ContentWrap, FlexColumn } from "../styles/Global";
import { Outlet } from "react-router-dom";
import BannerLogo from "../components/BannerLogo";
import { ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { setGlobal } from "../features/global/globalSlice";
import { clearModals } from "../features/modals/modalSlice";

const mapDispatch = {
  setGlobal,
  clearModals,
};

const NoNavLayout = ({ hideLogo, setGlobal, clearModals }) => {
  const [viewType, setViewType] = useState(0);
  const global = useSelector((state) => state.global);
  const darkMode = useSelector((state) => state.global.darkMode);
  const params = new URLSearchParams(window.location.search);
  const platform = useSelector((state) => state.organizer.platform);

  window.closeModals = async function () {
    try {
      console.log("closeModals called from window");
      clearModals();
    } catch (error) {
      console.log(error);
      console.log("window.closeModals ERROR: ", error);
    }
  };

  useEffect(() => {
    const localStorageGlobal = JSON.parse(localStorage.getItem("global")) || {};
    console.log("NoNavLayout localStorageGlobal: ", localStorageGlobal);

    const isOverlay =
      new URL(window.location.href).pathname.split("/")[3] === "overlay";
    const darkMode = isOverlay
      ? false
      : localStorageGlobal &&
        // window.location.hostname === "localhost" &&
        localStorageGlobal.hasOwnProperty("useCustomTheme") &&
        localStorageGlobal.useCustomTheme
      ? localStorageGlobal.customTheme
      : params.get("mode") !== "light"
      ? params.get("mode") === "dark" ||
        JSON.parse(localStorage.getItem("global")).darkMode
      : false;
    console.log("NoNavLayout.js darkMode: ", darkMode);

      setGlobal({
        darkMode: darkMode,
        urlParams: params.size > 0 ? params : {},
      });

    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    console.log("noNavLayout darkMode: ", darkMode);
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  useEffect(() => {
    if (platform) {
      // console.log("set body class mobile");
      document.body.classList.add("mobile");
    }
  }, [platform]);
  return (
    <ContentWrap maxWidth="600px">
      <FlexColumn align="center">
        {hideLogo ? null : <BannerLogo viewType={viewType} />}
        <Outlet />
      </FlexColumn>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ContentWrap>
  );
};

export default connect(null, mapDispatch)(NoNavLayout);
