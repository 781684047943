import React, { useEffect, useState } from "react";
import "../App.scss";
import { connect, useSelector } from "react-redux";
import { SlideUpModal, ModalOverlay } from "../styles/Global";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Outlet,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { setModal, clearModals } from "../features/modals/modalSlice";
import ChooseSport from "../pages/organizer/modals/ChooseSport";

const mapDispatch = { setModal };
function BannerLogo({
  viewType,
  setModal,
  disallowChangeSport,
  showSPR,
  maxWidth,
  showMainLogo,
  sportOverride,
}) {
  const player = useSelector((state) => state.player);
  const [displayModal, setDisplayModal] = useState(false);
  const { sport } = useParams();
  const platform = useSelector((state) => state.organizer.platform);
  const navType = new URL(window.location.href).pathname.split("/")[3];
  console.log("bannerlogo sport: ", sport);

  function setAppSport() {
    if (!platform && navType === "organizer") {
      setDisplayModal(true);
    } else {
      try {
        window.Android.changeSport();
      } catch (err) {
        console.log("android fail");
      }
      try {
        let appdata = {};
        appdata.option = "changesport";
        window.webkit.messageHandlers.changeSport.postMessage(
          JSON.stringify(appdata)
        );
      } catch (err) {
        console.log("webkit fail");
      }
    }
  }

  console.log("BannerLogo player: ", player);
  console.log("BannerLogo showSPR: ", showSPR);
  return (
    <>
      {displayModal && (
        <>
          <SlideUpModal maxWidth={"600px"} className="showModalWeb">
            <ChooseSport />
          </SlideUpModal>
          <ModalOverlay
            onClick={() => setDisplayModal(false)}
            className="showModal showOverlay"
          />
        </>
      )}
      <img
        src={
          showMainLogo
            ? require(`../assets/Horizontal_redoutline.png`)
            : player.paidOrganizer || player.paidPlayer || showSPR
            ? require(`../assets/sport/spr_banner_${
                sport ? sport : "cornhole"
              }.png`)
            : require(`../assets/sport/scoreholio_banner_${
                sport ? sport : sportOverride ? sportOverride : "cornhole"
              }.png`)
        }
        alt="Scoreholio logo"
        style={{
          maxWidth: maxWidth || 240,
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => (disallowChangeSport ? null : setAppSport())}
      />
    </>
  );
}

export default connect(null, mapDispatch)(BannerLogo);
