import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refreshMatchInfo: 0,
  refreshMainProfile: 0,
  refreshFindATournament: 0,
  refreshPracticeScreen: 0,
  refreshPracticeDetailsScreen: 0,
  refreshOrganizerScreen: 0,
  refreshSeasonsScreen: 0,
  refreshTournamentAlerts: 0,
  refreshFindTournamentGrid: 0,
  refreshExcludedList: 0,
  organizerTournamentsList: null,
  isOrgTournamentListLoading: false,
};

export const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    setRefreshMatchInfo(state, action) {
      console.log("setRefreshMatchInfo action: ", state.refreshMatchInfo++);
      state.refreshMatchInfo = state.refreshMatchInfo++;
    },
    setRefreshPracticeDetailsScreen(state, action) {
      console.log(
        "setRefreshPracticeDetailsScreen action: ",
        state.refreshPracticeDetailsScreen++
      );
      state.refreshPracticeDetailsScreen = state.refreshPracticeDetailsScreen++;
    },
    setRefreshMainProfile(state, action) {
      console.log("refreshMainProfile action: ", state.refreshMainProfile++);
      state.refreshMainProfile = state.refreshMainProfile++;
    },
    setRefreshFindATournament(state, action) {
      console.log(
        "refreshFindATournament action: ",
        state.refreshFindATournament++
      );
      state.refreshFindATournament = state.refreshFindATournament++;
    },
    setRefreshTournamentAlerts(state, action) {
      console.log(
        "refreshTournamentAlerts action: ",
        state.refreshTournamentAlerts++
      );
      state.refreshTournamentAlerts = state.refreshTournamentAlerts++;
    },
    setRefreshPracticeScreen(state, action) {
      console.log(
        "refreshPracticeScreen action: ",
        state.refreshPracticeScreen++
      );
      state.refreshPracticeScreen = state.refreshPracticeScreen++;
    },
    setRefreshFindTournamentGrid(state, action) {
      console.log(
        "refreshFindTournamentGrid action: ",
        state.refreshFindTournamentGrid++
      );
      state.refreshFindTournamentGrid = state.refreshFindTournamentGrid++;
    },
    setRefreshOrganizerScreen(state, action) {
      console.log(
        "refreshOrganizerScreen action: ",
        state.refreshOrganizerScreen++
      );
      state.refreshOrganizerScreen = state.refreshOrganizerScreen++;
    },
    setRefreshSeasonsScreen(state, action) {
      console.log(
        "setRefreshSeasonsScreen action: ",
        state.refreshSeasonsScreen++
      );
      state.refreshSeasonsScreen = state.refreshSeasonsScreen++;
    },
    setRefreshExcludedList(state, action) {
      console.log(
        "setRefreshExcludedList action: ",
        state.refreshExcludedList++
      );
      state.refreshExcludedList = state.refreshExcludedList++;
    },
    setOrganizerTournamentsList(state, action) {
      console.log("organizerTournamentsList action: ", action.payload);
      if (action?.payload?.organizerTournamentsList) {
        state.organizerTournamentsList =
          action.payload.organizerTournamentsList;
      }
    },
    setLoadingStatus(state, action) {
      console.log("setLoadingStatus action: ", action);
      const {
        isOrgTournamentListLoading,
      } = action.payload;
      state.isOrgTournamentListLoading =
        isOrgTournamentListLoading !== undefined &&
        isOrgTournamentListLoading !== null
          ? isOrgTournamentListLoading
          : state.isOrgTournamentListLoading;
    },
    clearRefresh: () => initialState,
  },
});

export const {
  setRefreshMatchInfo,
  setRefreshMainProfile,
  setRefreshPracticeScreen,
  setRefreshOrganizerScreen,
  setRefreshPracticeDetailsScreen,
  setRefreshTournamentAlerts,
  setRefreshSeasonsScreen,
  setRefreshFindATournament,
  setRefreshFindTournamentGrid,
  setRefreshExcludedList,
  setOrganizerTournamentsList,
  setLoadingStatus,
  clearRefresh,
} = refreshSlice.actions;

export default refreshSlice.reducer;
