import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teammates: [],
  user: null,
};

export const preRegistrationSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPreRegistration(state, action) {
      console.log("setPreRegistration preRegistrationSlice action: ", action);
      const { teammates, user } = action.payload;
      state.teammates = teammates || state.teammates;
      state.user = user || state.user;
    },
    setPreRegistrationNull(state, action) {
      console.log(
        "setPreRegistrationNull preRegistrationSlice action: ",
        action
      );
      const { teammates } = action.payload;
      state.teammates = teammates;
    },
    clearPreRegistration: () => initialState,
  },
});

export const {
  setPreRegistration,
  clearPreRegistration,
  setPreRegistrationNull,
} = preRegistrationSlice.actions;

export default preRegistrationSlice.reducer;
