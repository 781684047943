import { createSlice } from "@reduxjs/toolkit";
import { store } from '../../reducers/store'; // Adjust the path as necessary

const initialState = {
  orgModals: { showModal: false, modalType: 10, data: {} },
  clubProfileModals: { showModal: false, modalType: 50, data: {} },
  playerModals: { showModal: false, modalType: 25, data: {} },
  playerModals2: { showModal: false, modalType: 25, data: {} },
  playerModals3: { showModal: false, modalType: 25, data: {} },
  playerModals3ButtonData: { button: null },
  playerModals4: { showModal: false, modalType: 25, data: {} },
  mapModal: { showModal: false, modalType: 26, data: {} },
  globalModals: { showModal: false, modalType: 26, data: {} },
  globalModals2: { showModal: false, modalType: 25, data: {} },
  orgSeasonModals: { showModal: false, modalType: 22, data: {} },
  orgTournamentInfo: { showModal: false, modalType: 16, data: {} },
  requestCorrection: false,
  cancelCorrection: { data: {} },
  updatePracticeBags: false,
  updateTags: false,
  updateApp: false,
  organizerEvent: { showModal: false, data: {} },
  organizerSeasonsEvent: { showModal: false, data: {} },
  organizerCreateSeason: { showModal: false, data: {} },
  organizerCreateTournament: { showModal: false, data: {} },
  playerProfileModal: { showModal: false, modalType: 27, data: {} },
  practiceModals: { showModal: false, modalType: 28, data: {} },
  organizerInitial: { showModal: false, modalType: 29, data: {} },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal(state, action) {
      console.log("modalSlice action: ", action.payload);
      const isAnyModalOpen = () => {
        //Check if any modal is currently open
        return Object.values(state).some(
          (modal) => modal?.showModal && modal.showModal === true
        );
      };
      const checkModalsAfterUpdate = () => {
        const anyModalOpen = isAnyModalOpen();
        if (anyModalOpen) {
          console.log(
            "Post state update: At least one modal is open: ",
            anyModalOpen
          );
          // Run your function to handle at least one modal open
          try {
            window.Android.modalStatus("open");
          } catch (err) {
            console.log("android fail");
          }
          try {
            window.webkit.messageHandlers.modalStatus.postMessage("open");
          } catch (err) {
            console.log("webkit fail");
          }
        } else {
          console.log("Post state update: No modals are open: ", anyModalOpen);
          // Run your function to handle no modals open
          try {
            window.Android.modalStatus("closed");
          } catch (err) {
            console.log("android fail");
          }
          try {
            window.webkit.messageHandlers.modalStatus.postMessage("closed");
          } catch (err) {
            console.log("webkit fail");
          }
        }
      };
      console.log("isAnyModalOpen PRE-action logic: ", isAnyModalOpen());
      const {
        requestCorrection,
        cancelCorrection,
        organizerEvent,
        updatePracticeBags,
        updateTags,
        updateApp,
        organizerCreateTournament,
        orgModals,
        clubProfileModals,
        orgSeasonModals,
        orgTournamentInfo,
        organizerSeasonsEvent,
        organizerCreateSeason,
        playerModals,
        playerModals2,
        playerModals3,
        playerModals3ButtonData,
        playerModals4,
        globalModals2,
        globalModals,
        mapModal,
        playerProfileModal,
        practiceModals,
        organizerInitial,
      } = action.payload;
      state.orgModals = orgModals || initialState.orgModals;
      state.clubProfileModals =
        clubProfileModals ||
        state.clubProfileModals ||
        initialState.clubProfileModals;
      state.globalModals =
        globalModals || state.globalModals || initialState.globalModals;
      state.mapModal = mapModal || state.mapModal || initialState.mapModal;
      state.playerModals =
        playerModals || state.playerModals || initialState.playerModals;
      state.playerModals2 =
        playerModals2 || state.playerModals2 || initialState.playerModals2;
      state.playerModals3 =
        playerModals3 || state.playerModals3 || initialState.playerModals3;
      state.playerModals3ButtonData =
        playerModals3ButtonData ||
        state.playerModals3ButtonData ||
        initialState.playerModals3ButtonData;
      state.playerModals4 =
        playerModals4 || state.playerModals4 || initialState.playerModals4;
      state.globalModals2 =
        globalModals2 || state.globalModals2 || initialState.globalModals2;
      state.orgSeasonModals = orgSeasonModals || initialState.orgSeasonModals;
      state.orgTournamentInfo =
        orgTournamentInfo || initialState.orgTournamentInfo;
      state.requestCorrection = requestCorrection;
      state.cancelCorrection = cancelCorrection;
      state.updatePracticeBags = updatePracticeBags;
      state.organizerEvent = organizerEvent || initialState.organizerEvent;
      state.organizerSeasonsEvent =
        organizerSeasonsEvent || initialState.organizerSeasonsEvent;
      state.organizerCreateTournament =
        organizerCreateTournament ||
        state.organizerCreateTournament ||
        initialState.organizerCreateTournament;
      state.organizerCreateSeason =
        organizerCreateSeason ||
        state.organizerCreateSeason ||
        initialState.organizerCreateSeason;
      state.playerProfileModal =
        playerProfileModal ||
        state.playerProfileModal ||
        initialState.playerProfileModal;
      state.practiceModals =
        practiceModals ||
        state.practiceModals ||
        initialState.practiceModals;
      state.organizerInitial =
        organizerInitial ||
        state.organizerInitial ||
        initialState.organizerInitial;
      state.updateApp = updateApp;
      state.updateTags = updateTags;

      checkModalsAfterUpdate();
    },
    clearModals: (state) => {
      console.log("clearModals dispatch called and running");

      // Mutate draft state only, don't return
      Object.keys(state).forEach((key) => {
        if (
          state[key] &&
          typeof state[key] === "object" &&
          "showModal" in state[key]
        ) {
          state[key].showModal = false;
          state[key].modalType = 0;
          state[key].data = {};
        }
      });

      // Set any other specific state resets needed
      state.requestCorrection = false;
      state.cancelCorrection = false;
      state.updatePracticeBags = false;
      state.updateApp = false;
      state.updateTags = false;
    },
  },
});

export const { setModal, clearModals } = modalSlice.actions;


export default modalSlice.reducer;
