import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

// console.log("process.env: ", process.env);
// console.log("process.env api base: ", process.env.REACT_APP_API_BASE_URL);

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/apiv2/dashboard/`,
  }),
  endpoints: (builder) => ({
    getMatchInfo: builder.mutation({
      query: (body) => ({
        url: `matchdetail/matchinfo`,
        method: "POST",
        body: body,
      }),
    }),
    getScoremagic: builder.mutation({
      query: (body) => ({
        url: `matchdetail/scoremagic`,
        method: "POST",
        body: body,
      }),
    }),
  }),
})

export const { useGetMatchInfoMutation, useGetScoremagicMutation } =
  dashboardApi
