// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIGREl2kwv1POdGT6ip9W8jxhWRqQAmTE",
  authDomain: "scoreholio.firebaseapp.com",
  databaseURL: "https://scoreholio.firebaseio.com",
  projectId: "scoreholio",
  storageBucket: "scoreholio.appspot.com",
  messagingSenderId: "113741626167",
  appId: "1:113741626167:web:29bc6a551f56c69f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
