import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: null,
  bearerToken: null,
  accountid: null,
  platform: null,
  organizer: null,
  organizerData: null,
  isLogout: false,
  build: null,
  clubImage: "",
  seasonImage: "",
};

export const organizerSlice = createSlice({
  name: "organizer",
  initialState,
  reducers: {
    setOrganizer(state, action) {
      console.log("set organizerSlice action: ", action);
      const {
        auth,
        organizer,
        bearerToken,
        organizerData,
        accountid,
        isLogout,
        platform,
        build,
        clubImage,
        seasonImage,
      } = action.payload;
      state.isLogout =
        isLogout || isLogout === false || isLogout === null
          ? isLogout
          : state.isLogout;
      state.auth = auth || auth === null ? auth : state.auth;
      state.accountid =
        accountid || accountid === null ? accountid : state.accountid;
      state.platform =
        platform || platform === null ? platform : state.platform;
      state.build = build || build === null ? build : state.build;
      state.clubImage =
        clubImage || clubImage === null ? clubImage : state.clubImage;
      state.seasonImage =
        seasonImage !== null ? seasonImage : state.seasonImage;
      state.organizer =
        organizer || organizer === null ? organizer : state.organizer;
      state.bearerToken =
        bearerToken || bearerToken === null ? bearerToken : state.bearerToken;
      state.organizerData =
        organizerData || organizerData === null
          ? organizerData
          : state.organizerData;
      localStorage.setItem("organizer", JSON.stringify({ ...state }));
    },
    clearOrganizer: () => initialState,
  },
});

export const { setOrganizer, clearOrganizer } = organizerSlice.actions;

export default organizerSlice.reducer;
