import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayName: "",
  pin: null,
  userID: null,
  email: null,
  paidOrganizer: false,
  paidPlayer: false,
  isAdmin: false,
  sport: null,
  token: null,
  accountID: null,
  profileData: {},
  isUnverified: false,
  build: 0,
  platform: null,
  requestBody: {},
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayer(state, action) {
      console.log("set player playerSlice action: ", action);
      const {
        displayName,
        pin,
        userid,
        userID,
        email,
        token,
        paidOrganizer,
        paidPlayer,
        isAdmin,
        sport,
        profileData,
        accountid,
        isUnverified,
        build,
        platform,
        requestBody,
      } = action.payload;
      state.displayName = displayName || state.displayName;
      state.userID = userid || userID || state.userID;
      state.pin = pin || state.pin;
      state.email = email || state.email;
      state.token = token || state.token;
      state.paidPlayer = paidPlayer || state.paidPlayer;
      state.paidOrganizer = paidOrganizer || state.paidOrganizer;
      state.isAdmin = isAdmin || state.isAdmin;
      state.sport = sport || state.sport;
      state.profileData = profileData || state.profileData;
      state.accountID = accountid || state.accountID;
      state.isUnverified = isUnverified || state.isUnverified;
      state.build = build || state.build;
      state.platform = platform || state.platform;
      state.requestBody = requestBody || state.requestBody;
      localStorage.setItem("player", JSON.stringify({ ...state }));
    },
    setPlayerNull(state, action) {
      console.log("setPlayerNull playerSlice action: ", action);
      const {
        displayName,
        pin,
        userid,
        userID,
        email,
        token,
        paidOrganizer,
        paidPlayer,
        isAdmin,
        sport,
        profileData,
        accountid,
        isUnverified,
      } = action.payload;
      state.displayName = displayName;
      state.userID = userid || userID;
      state.pin = pin;
      state.email = email;
      state.token = token;
      state.paidPlayer = paidPlayer;
      state.paidOrganizer = paidOrganizer;
      state.isAdmin = isAdmin;
      state.sport = sport;
      state.profileData = profileData;
      state.accountID = accountid;
      state.isUnverified = isUnverified;
      localStorage.setItem("player", JSON.stringify({ ...state }));
    },
    clearPlayer: () => initialState,
  },
});

export const { setPlayer, clearPlayer, setPlayerNull } = playerSlice.actions;

export default playerSlice.reducer;
