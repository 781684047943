import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const emailApi = createApi({
  reducerPath: "emailApi",
  baseQuery: fetchBaseQuery({
    //Can probably create an API variables export file to manage and set all base URL's if needed later
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/apiv2/email/`,
  }),
  endpoints: (builder) => ({
    emailDebounceCheck: builder.mutation({
      query: (body) => ({
        url: `check`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useEmailDebounceCheckMutation } = emailApi;
