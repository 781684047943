import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Text,
  ContentSection,
  FlexColumn,
  TextOnlyButton,
  FlexRow,
  LargeButton,
  LargeButtonSecondary,
  ButtonText,
  SlideUpModal,
  ModalOverlay,
  FormInputField,
  Accordion,
  FormSelectField,
} from "../../../styles/Global";
import * as Global from "../../../styles/Global";
import { connect, useSelector } from "react-redux";

const ChooseSport = ({ setDisplayModal, isHome }) => {
  const sports = [
    { sport: "21bust", name: "21 or Bust" },
    { sport: "axethrowing", name: "Axe Throwing" },
    { sport: "billiards", name: "Billiards" },
    { sport: "bocce", name: "Bocce" },
    { sport: "cornhole", name: "Cornhole" },
    { sport: "darts", name: "Darts" },
    { sport: "horseshoes", name: "Horseshoes" },
    { sport: "pickleball", name: "Pickleball" },
    { sport: "pong", name: "Pong" },
    { sport: "tableshuffleboard", name: "Shuffleboard" },
    { sport: "tabletennis", name: "Table Tennis" },
    { sport: "tennis", name: "Tennis" },
    { sport: "volleyball", name: "Volleyball" },
    { sport: "other", name: "Other Sport" },
  ];

  function UpdateURLSport(sport) {
    const path = new URL(window.location.href).origin;
    // console.log("path: ", path);
    // console.log(
    //   "actual path to navigate to: ",
    //   `${path}/v2/${sport}/organizer`
    // );
    window.location.replace(`${path}/v2/${sport}/organizer`);
  }
  return (
    <FlexColumn>
      <img
        src={require(`../../../assets/Horizontal_redoutline.png`)}
        alt="Scoreholio logo"
        style={{ maxWidth: 350, margin: "10px auto" }}
      />
      <FlexColumn
        style={{ flex: 1, width: "100%", margin: "20px 0" }}
        align="center"
        justify="center"
      >
        <Text size="1.3em">Tournaments, Simplified.</Text>
        <Text size=".9em" style={{ textAlign: "center", marginTop: 15 }}>
          Choose your sport to login/create an organizer account.
        </Text>
      </FlexColumn>
      <FlexRow
        align="center"
        justify="center"
        style={{ flexWrap: "wrap", width: "100%" }}
      >
        {sports.map((sport) => {
          return (
            <div style={{ width: "50%", padding: "8px" }}>
              <LargeButton
                className="largeButtonHover"
                style={{ width: "100%" }}
                onClick={() => UpdateURLSport(sport.sport)}
              >
                <ButtonText>{sport.name}</ButtonText>
              </LargeButton>
            </div>
          );
        })}
      </FlexRow>
    </FlexColumn>
  );
};

export default ChooseSport;
