import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playerPPR: null,
  playerSPR: null,
  playerRank: null,
  practicePPRScatter: null,
};

export const chartDataSlice = createSlice({
  name: "chartData",
  initialState,
  reducers: {
    setChartData(state, action) {
      //console.log("set player chartDataSlice action");
      const { playerPPR, playerSPR, playerRank, practicePPRScatter } =
        action.payload;
      state.playerPPR = playerPPR || state.playerPPR;
      state.playerSPR = playerSPR || state.playerSPR;
      state.playerRank = playerRank || state.playerRank;
      state.practicePPRScatter = practicePPRScatter || state.practicePPRScatter;
    },
    clearPlayer: () => initialState,
  },
});

export const { setChartData } = chartDataSlice.actions;

export default chartDataSlice.reducer;
