import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { auth } from "./firebaseConfig"
import { connect, useSelector } from "react-redux"
import { setOrganizer } from "../features/organizer/organizerSlice"
import { signInWithCustomToken } from "firebase/auth"
import { store } from "../reducers/store"
const params = new URLSearchParams(window.location.search)
const platform = params.get("platform")
const localStorageOrganizer = JSON.parse(localStorage.getItem("organizer"))

function HandleError(err) {
  console.log("login HandleError: ", err)
}

async function RunAuthActions(result, args, api, extraOptions) {
  console.log("RunAuthActions")

  try {
    console.log("organizerApi auth.currentUser: ", auth)
    const getToken = async () => {
      return await auth.currentUser.getIdToken(true)
    }
    const updatedToken = await getToken().catch(HandleError)
    console.log(" organizerApi updatedToken: ", updatedToken)
    if (updatedToken) {
      //Store new token
      api.dispatch(setOrganizer({ bearerToken: updatedToken }))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
      return result
    } else {
      if (platform === "ios") {
        window.webkit.messageHandlers.requestAccessToken.postMessage("request")
        window.setJwtExternal = async function (token) {
          //iOS native login function
          try {
            const jwtSignIn = await signInWithCustomToken(auth, token)
            console.log("ios jwtSignIn: ", jwtSignIn)
            api.dispatch(
              setOrganizer({ bearerToken: jwtSignIn.user.accessToken })
            )
            // setRefreshOrganizerScreen();
          } catch (error) {
            console.log("setJwtExternal ERROR: ", error)
          }
        }
      } else if (platform === "android") {
        async function LoginAndroid() {
          console.log("LoginAndroid")
          //Android native login function
          const idTokenFromApp = window.Android.getAccessToken()
          if (idTokenFromApp) {
            const jwtSignIn = await signInWithCustomToken(auth, idTokenFromApp)
            api.dispatch(
              setOrganizer({ bearerToken: jwtSignIn.user.accessToken })
            )
          }
        }
        LoginAndroid()
      } else {
        //Platform is Web without a user
        console.log("platform is web and !user: ")
        // console.log("localStorageOrganizer from auth: ", localStorageOrganizer);
        api.dispatch(setOrganizer(localStorageOrganizer))
        // console.log("okay retrying auth again with new baseQuery");
        result = await baseQuery(args, api, extraOptions)
        return result
      }
    }
  } catch (err) {
    console.log("Attempt to fire native login fail")
    if (!platform) {
      const path = new URL(window.location.href).origin
      const sport = new URL(window.location.href).pathname.split("/")[2]
      window.location.replace(`${path}/v2/${sport}/organizer`)
    }
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
  prepareHeaders: async (headers, { getState, dispatch }) => {
    const getToken = async () => {
      return await auth.currentUser.getIdToken(true)
    }
    const updatedToken = await getToken().catch(HandleError)
    // console.log("updatedToken: ", updatedToken);
    const token = getState().organizer.bearerToken
    const tokenToUse = updatedToken
      ? updatedToken
      : token
      ? token
      : localStorageOrganizer && localStorageOrganizer.bearerToken
      ? localStorageOrganizer.bearerToken
      : token
    // console.log("tokenToUse: ", tokenToUse);
    headers.set("authorization", `Bearer ${tokenToUse}`)
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const globalState = store.getState();
  console.log("globalState: ", globalState);
  let result;
  if (!globalState.organizer.isLogout) {
    console.log("baseQueryWithReauth running!");
    if (!platform && !globalState.organizer.bearerToken) {
      console.log("baseQueryWithReauth: there is no token !");
      api.dispatch(setOrganizer(localStorageOrganizer));
    }
    result = await baseQuery(args, api, extraOptions);
    console.log("baseQueryWithReauth result: ", result);
    if (result.error && result.error.status > 399) {
      console.log("baseQueryWithReauth running 400! auth: ", auth);
      // try to get a new token
      RunAuthActions(result, args, api, extraOptions);
    }
    return result;
  }
  return result;
};

export const organizerApi = createApi({
  reducerPath: "organizerApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getProfileSingle: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/profile`,
        method: "POST",
        body: body,
      }),
    }),
    updateProfileSingle: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/update-profile`,
        method: "POST",
        body: body,
      }),
    }),
    getStats: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/charts/stats`,
        method: "POST",
        body: body,
      }),
    }),
    updateProfileImage: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/update-profile-image`,
        method: "POST",
        body: body,
      }),
    }),
    playerStats: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/player-stats`,
        method: "POST",
        body: body,
      }),
    }),
    updateTournamentForm: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/tournament/update`,
        method: "POST",
        body: body,
      }),
    }),
    getTournamentForm: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/tournament/get`,
        method: "POST",
        body: body,
      }),
    }),
    getTournamentFormExtraInfo: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/tournament/extra-info`,
        method: "POST",
        body: body,
      }),
    }),
    getTournamentFormTimezoneLookup: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/tournament/timezone-lookup`,
        method: "POST",
        body: body,
      }),
    }),
    getTournamentAlerts: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/pushalerts/get`,
        method: "POST",
        body: body,
      }),
    }),
    sendTournamentAlert: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/pushalerts/send`,
        method: "POST",
        body: body,
      }),
    }),
    checkAlertCount: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/pushalerts/check`,
        method: "POST",
        body: body,
      }),
    }),
    getTournaments: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/tournaments`,
        method: "POST",
        body: body,
      }),
    }),
    deleteOrganizerAccount: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/profile/delete`,
        method: "POST",
        body: body,
      }),
    }),
    addExcludedPlayer: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/excludedplayers/add`,
        method: "POST",
        body: body,
      }),
    }),
    deleteExcludedPlayer: builder.mutation({
      query: (body) => ({
        url: `apisecurev2/organizer/excludedplayers/delete`,
        method: "POST",
        body: body,
      }),
    }),
    getSeason: builder.mutation({
      query: (body) => ({
        url: `connect/organizer/grid/get-season`,
        method: "POST",
        body: body,
      }),
    }),
    endOfYearOrganizer: builder.mutation({
      query: (body) => ({
        url: `apiv2/organizer/thatsawrap`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetProfileSingleMutation,
  useUpdateProfileSingleMutation,
  useGetStatsMutation,
  useUpdateProfileImageMutation,
  usePlayerStatsMutation,
  useUpdateTournamentFormMutation,
  useGetTournamentFormMutation,
  useGetTournamentFormExtraInfoMutation,
  useGetTournamentFormTimezoneLookupMutation,
  useGetTournamentAlertsMutation,
  useSendTournamentAlertMutation,
  useGetTournamentsMutation,
  useCheckAlertCountMutation,
  useGetSeasonMutation,
  useDeleteOrganizerAccountMutation,
  useEndOfYearOrganizerMutation,
  useAddExcludedPlayerMutation,
  useDeleteExcludedPlayerMutation,
} = organizerApi;
