import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  players: null,
};

export const organizerPlayersSlice = createSlice({
  name: "organizerPlayers",
  initialState,
  reducers: {
    setOrganizerPlayers(state, action) {
      console.log("set player organizerPlayersSlice action: ", action);
      const { players } = action.payload;
      state.players = players;
    },
    clearOrganizerPlayers: () => initialState,
  },
});

export const { setOrganizerPlayers, clearOrganizerPlayers } =
  organizerPlayersSlice.actions;

export default organizerPlayersSlice.reducer;
