import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


// console.log("process.env: ", process.env);
// console.log("process.env api base: ", process.env.REACT_APP_API_BASE_URL);

export const findTournamentApi = createApi({
  reducerPath: "findTournamentApi",
  baseQuery: fetchBaseQuery({
    //Can probably create an API variables export file to manage and set all base URL's if needed later
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/apiv2/find/`,
  }),
  endpoints: (builder) => ({
    getTournaments: builder.mutation({
      query: (body) => ({
        url: `get-tournaments`,
        method: "POST",
        body: body,
      }),
    }),
    getFollowers: builder.mutation({
      query: (body) => ({
        url: `get-followers`,
        method: "POST",
        body: body,
      }),
    }),
    updateFollowers: builder.mutation({
      query: (body) => ({
        url: `update-followers`,
        method: "POST",
        body: body,
      }),
    }),
    updateFavorites: builder.mutation({
      query: (body) => ({
        url: `update-favorites`,
        method: "POST",
        body: body,
      }),
    }),
    getShareLink: builder.mutation({
      query: (body) => ({
        url: `get-share-link`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTournamentsMutation,
  useGetFollowersMutation,
  useUpdateFollowersMutation,
  useUpdateFavoritesMutation,
  useGetShareLinkMutation,
} = findTournamentApi;
