import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stats: null,
};

export const organizerStatsSlice = createSlice({
  name: "organizerStats",
  initialState,
  reducers: {
    setOrganizerStats(state, action) {
      console.log("set player organizerStatsSlice action: ", action);
      const { stats } = action.payload;
      state.stats = stats;
    },
    clearOrganizerStats: () => initialState,
  },
});

export const { setOrganizerStats, clearOrganizerStats } =
  organizerStatsSlice.actions;

export default organizerStatsSlice.reducer;
