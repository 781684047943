import React, { useEffect, useState } from "react";
import "../App.scss";
import { connect, useSelector } from "react-redux";
import { ContentWrap, FlexColumn } from "../styles/Global";
import { Outlet } from "react-router-dom";
import BannerLogo from "../components/BannerLogo";
import { setGlobal } from "../features/global/globalSlice";

const mapDispatch = { setGlobal };

const UpgradeLayout = ({ hideLogo, setGlobal }) => {
  const nav = new URL(window.location.href).pathname.split("/")[1];
  console.log("nav: ", nav);
  const global = useSelector((state) => state.global);
  const params = new URLSearchParams(window.location.search);
  const [viewType, setViewType] = useState(0);
  const localStorageGlobal =
    JSON.parse(localStorage.getItem("global")) || global;

  function SetGlobalStatus(param, storage) {
    let theme = false;
    const mode = params.get(param);
    console.log("upgradelayout mode param: ", mode);
    if (!mode) {
      theme = storage;
    } else if (mode == "dark") {
      theme = true;
    }
    return theme;
  }

  console.log("globalState upgradelayout: ", global);

  useEffect(() => {
    setGlobal({
      darkMode: SetGlobalStatus("mode", localStorageGlobal.darkMode),
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("upgrade-bg");
    return () => {
      document.body.classList.remove("upgrade-bg");
    };
  });
  return (
    <ContentWrap maxWidth="600px">
      <FlexColumn align="center">
        <Outlet />
      </FlexColumn>
    </ContentWrap>
  );
};

export default connect(null, mapDispatch)(UpgradeLayout);
